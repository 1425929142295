<template>
  <div class="index">
    <div class="index-banner">
      <el-carousel :interval="5000" arrow="always" :height="bannerHeight + 'px'">
        <el-carousel-item v-for="(item, i) in banner" :key="i">
          <el-image :src="item" class="index-banner-pic" fit="cover"></el-image>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="df-width index-new">
      <div class="index-new-one">
        <div class="news-title">重击算法滥用 干掉“大数据杀熟”</div>
        <div class="news-box">
          <el-image :src="newsPic" class="news-pic" lazy fit="cover"></el-image>
          <div class="news-content">
            <div class="news-content-one">
              大数据杀熟，这是很多互联网公司擅长的东西。而它们利用的技术工具，便是算法。当这些公司掌握了一定的用户数据后，就可以利用算法推荐的方式，潜移默化的影响用户决策。 比如，在电商平台，买同样的一件商品，不同的人价格很可能不同。经常在这个平台买东西.
            </div>
            <div class="news-content-two" @click="goToUrl('http://www.cnit5.com/a/hulianwangxinwen/2022/0302/15689.html')">查看详情>></div>
          </div>
        </div>
      </div>
      <div class="index-new-two">
        <el-tabs class="news-tabs" v-model="tabName" @tab-click="handleClick">
          <el-tab-pane label="行业新闻" name="行业新闻">
            <div class="news-tabs-box">
              <div class="news-tabs-item">
                <div class="news-tabs-item-box" @click="goToUrl('http://www.cnit5.com/a/ITyejie/2022/0302/15690.html')">
                  <div class="news-tabs-date">2022.03.02</div>
                  <div class="news-tabs-title">平板市场竞逐：苹果独大、联想猛增，Ov们还有机会吗？</div>
                </div>
                <div class="news-tabs-item-box" @click="goToUrl('http://www.cnit5.com/a/hulianwangxinwen/2022/0301/15677.html')">
                  <div class="news-tabs-date">2022.03.01</div>
                  <div class="news-tabs-title">美、英发现新的僵尸网络恶意程序 Cyclops Blink</div>
                </div>
                <div class="news-tabs-item-box" @click="goToUrl('http://www.cnit5.com/a/hulianwangxinwen/2021/0120/15564.html')">
                  <div class="news-tabs-date">2021.01.20</div>
                  <div class="news-tabs-title">柔宇科技刘自鸿博士荣获第二十三届中国科协求是杰出青年奖</div>
                </div>
              </div>
              <div class="news-tabs-more">查看详情>></div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="公司动态" name="公司动态">
            <div class="news-tabs-box">
              <div class="news-tabs-item">
                <div class="news-tabs-item-box" @click="goToUrl('https://www.gzwyufei.work/index.html')">
                  <div class="news-tabs-date">2022.01.01</div>
                  <div class="news-tabs-title">基础服务器环境配置教程</div>
                </div>
                <div class="news-tabs-item-box" @click="goToUrl('https://www.gzwyufei.work/git.html')">
                  <div class="news-tabs-date">2022.01.01</div>
                  <div class="news-tabs-title">团队开发Git命令基础使用</div>
                </div>
                <div class="news-tabs-item-box" @click="goToUrl('http://canvas.gzwyufei.work/git.html')">
                  <div class="news-tabs-date">2022.01.01</div>
                  <div class="news-tabs-title">Vue临时拼接图片生成下载</div>
                </div>
              </div>
              <div class="news-tabs-more">查看详情>></div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <div class="df-width index-case">
      <div class="index-case-title">
        <div class="index-case-label">解决方案</div>
        <div class="index-case-line"></div>
        <div class="index-case-b-label">TOTAL SOLUTION</div>
      </div>
      <div class="index-case-box">
        <div class="index-case-item" @click="goToUrl('/plan/first.html')">
          <el-image class="index-case-item-pic" :src="casePic" lazy fit="cover"></el-image>
          <div class="index-case-item-title">连锁企业管理系统</div>
          <div class="index-case-item-line"></div>
          <div class="index-case-item-desc">高效门店管理，收款分账一步到位</div>
        </div>
        <div class="index-case-item" @click="goToUrl('/plan/second.html')">
          <el-image class="index-case-item-pic" :src="casePic2" lazy fit="cover"></el-image>
          <div class="index-case-item-title">协会组织管理系统</div>
          <div class="index-case-item-line"></div>
          <div class="index-case-item-desc">数字化协会管理，智能化运营体系</div>
        </div>
        <div class="index-case-item" @click="goToUrl('/plan/third.html')">
          <el-image class="index-case-item-pic" :src="casePic3" lazy fit="cover"></el-image>
          <div class="index-case-item-title">教培行业管理系统</div>
          <div class="index-case-item-line"></div>
          <div class="index-case-item-desc">轻松招生，智能考勤，报班选课，一键就行</div>
        </div>
<!--        <div class="index-case-item" @click="goToUrl('/plan/fourth.html')">-->
<!--          <el-image class="index-case-item-pic" :src="casePic4" lazy fit="cover"></el-image>-->
<!--          <div class="index-case-item-title">幼教平台"童悦乐园"</div>-->
<!--          <div class="index-case-item-line"></div>-->
<!--          <div class="index-case-item-desc">幼教平台"童悦乐园"</div>-->
<!--        </div>-->
<!--        <div class="index-case-item" @click="goToUrl('/plan/fifth.html')">-->
<!--          <el-image class="index-case-item-pic" :src="casePic5" lazy fit="cover"></el-image>-->
<!--          <div class="index-case-item-title">网络系统建站服务</div>-->
<!--          <div class="index-case-item-line"></div>-->
<!--          <div class="index-case-item-desc">网络系统建站服务</div>-->
<!--        </div>-->
<!--        <div class="index-case-item" @click="goToUrl('/plan/sixth.html')">-->
<!--          <el-image class="index-case-item-pic" :src="casePic6" lazy fit="cover"></el-image>-->
<!--          <div class="index-case-item-title">安防系统</div>-->
<!--          <div class="index-case-item-line"></div>-->
<!--          <div class="index-case-item-desc">安防系统</div>-->
<!--        </div>-->
      </div>
    </div>
    <div class="index-sat">
      <div class="index-sat-tip">
        <div class="index-sat-item">
          <div class="index-sat-item-title">7 x 24</div>
          <div class="index-sat-item-desc">在线客服</div>
        </div>
        <div class="index-sat-item">
          <div class="index-sat-item-title">50+</div>
          <div class="index-sat-item-desc">政府企事业单位合作伙伴</div>
        </div>
        <div class="index-sat-item">
          <div class="index-sat-item-title">5年+</div>
          <div class="index-sat-item-desc">互联网服务诚信服务商</div>
        </div>
        <div class="index-sat-item">
          <div class="index-sat-item-title">99%</div>
          <div class="index-sat-item-desc">合作客户满意度</div>
        </div>
      </div>
      <div class="index-sat-btn">
        <el-button class="index-sat-btn-more" type="danger" @click="goToAbout">了解更多</el-button>
      </div>
    </div>
    <div class="df-width index-pro">
      <div class="index-case-title">
        <div class="index-case-label">经典案例</div>
        <div class="index-case-line"></div>
        <div class="index-case-b-label">CLASSIC CASES</div>
      </div>
      <div class="index-pro-box">
        <div class="index-pro-item" @click="goToUrl('/case/1.html')">
          <el-image class="index-pro-item-pic" :src="proPic1" lazy fit="cover"></el-image>
          <div class="index-pro-item-title">珠海市青少年妇女儿童活动小程序</div>
          <div class="index-pro-item-desc">
            活动中心是珠海市团市委、市妇联属下的一个集培训、活动、娱乐等功能为一体的校外教育事业单位。活动
          </div>
        </div>
        <div class="index-pro-item" @click="goToUrl('/case/1.html')">
          <el-image class="index-pro-item-pic" :src="proPic2" lazy fit="cover"></el-image>
          <div class="index-pro-item-title">珠海市青年联合会小程序</div>
          <div class="index-pro-item-desc">
            珠海市青年联合会小程序是一款集新闻发布、活动报名、信息记录的专属珠海青联委员使用的应用小程序
          </div>
        </div>
        <div class="index-pro-item" @click="goToUrl('/case/1.html')">
          <el-image class="index-pro-item-pic" :src="proPic3" lazy fit="cover"></el-image>
          <div class="index-pro-item-title">珠海华发物业管理服务有限公司官网</div>
          <div class="index-pro-item-desc">
            基于珠海物业管理行业的第一品牌和领跑者的定位和形象，珠海华发物业管理有限公司的网站设计充分体现
          </div>
        </div>
        <div class="index-pro-item" @click="goToUrl('/case/1.html')">
          <el-image class="index-pro-item-pic" :src="proPic4" lazy fit="cover"></el-image>
          <div class="index-pro-item-title">嘉宝华集团【大粤地产】联合办公小程序</div>
          <div class="index-pro-item-desc">
            联合办公小程序是一款为联合办公打造的高端服务平台，为客户解决了实际线下的业务流程，并解决了高端
          </div>
        </div>
        <div class="index-pro-item" @click="goToUrl('/case/1.html')">
          <el-image class="index-pro-item-pic" :src="proPic5" lazy fit="cover"></el-image>
          <div class="index-pro-item-title">珠海青年人才驿站服务平台</div>
          <div class="index-pro-item-desc">
            珠海青年人才驿站是为来珠海求职的青年人才提供免费住宿、就业指导、城市融入等相关服务，精准服务青
          </div>
        </div>
        <div class="index-pro-item" @click="goToUrl('/case/1.html')">
          <el-image class="index-pro-item-pic" :src="proPic6" lazy fit="cover"></el-image>
          <div class="index-pro-item-title">珠海市人民医院官网</div>
          <div class="index-pro-item-desc">
            珠海市人民医院新官网，将互联网技术与珠海市人民医院服务理念、诊疗特点、科研成果进行有机结合，构造
          </div>
        </div>
      </div>
      <div class="index-pro-btn">
        <el-button class="index-pro-btn-more" type="danger" @click="goToCase">了解更多</el-button>
      </div>
    </div>
    <div class="index-link">
      <div class="df-width">
        <div class="index-case-title">
          <div class="index-case-label">合作伙伴</div>
          <div class="index-case-line"></div>
          <div class="index-case-b-label">COOPERATIVE PARTNER</div>
        </div>
        <div class="index-link-banner">
          <el-carousel :interval="5000" arrow="always" height="180px">
            <el-carousel-item>
              <div class="index-link-box">
                <div class="index-link-item"><el-image :src="txy" fit="contain"></el-image></div>
                <div class="index-link-item"><el-image :src="zgyd" fit="contain"></el-image></div>
                <div class="index-link-item"><el-image :src="hd" fit="contain"></el-image></div>
                <div class="index-link-item"><el-image :src="zf" fit="contain"></el-image></div>
                <div class="index-link-item"><el-image :src="fl" fit="contain"></el-image></div>
                <div class="index-link-item"><el-image :src="hf" fit="contain"></el-image></div>
                <div class="index-link-item mt-15"><el-image :src="hf" fit="contain"></el-image></div>
                <div class="index-link-item mt-15"><el-image :src="fl" fit="contain"></el-image></div>
                <div class="index-link-item mt-15"><el-image :src="zf" fit="contain"></el-image></div>
                <div class="index-link-item mt-15"><el-image :src="hd" fit="contain"></el-image></div>
                <div class="index-link-item mt-15"><el-image :src="zgyd" fit="contain"></el-image></div>
                <div class="index-link-item mt-15"><el-image :src="txy" fit="contain"></el-image></div>
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Index',
  data () {
    return {
      bannerHeight: document.documentElement.clientWidth * 7 / 24,
      banner: [
        require('@/assets/images/banner.png')
        // require('@/assets/images/banner.png'),
        // require('@/assets/images/banner.png')
      ],
      newsPic: require('@/assets/images/news.png'),
      tabName: '行业新闻',
      casePic: require('@/assets/images/case1.png'),
      casePic2: require('@/assets/images/case2.png'),
      casePic3: require('@/assets/images/case3.png'),
      casePic4: require('@/assets/images/tyyey.png'),
      casePic5: require('@/assets/images/wlxt.png'),
      casePic6: require('@/assets/images/afxt.png'),
      proPic1: require('@/assets/images/pro1.png'),
      proPic2: require('@/assets/images/pro2.png'),
      proPic3: require('@/assets/images/pro3.png'),
      proPic4: require('@/assets/images/pro4.png'),
      proPic5: require('@/assets/images/pro5.png'),
      proPic6: require('@/assets/images/pro6.png'),
      txy: require('@/assets/images/txy.png'),
      zgyd: require('@/assets/images/zgyd.png'),
      hd: require('@/assets/images/hd.png'),
      zf: require('@/assets/images/zf.png'),
      fl: require('@/assets/images/fl.png'),
      hf: require('@/assets/images/hf.png')
    }
  },
  methods: {
    goToCase () {
      window.location.href = '/case.html'
    },
    goToAbout () {
      window.location.href = '/about.html'
    },
    goToUrl (url) {
      window.open(url)
    }
  },
  mounted () {
    const that = this
    window.onresize = () => {
      return (() => {
        that.bannerHeight = document.documentElement.clientWidth * 7 / 24
      })()
    }
  }
}
</script>

<style scoped>
  :deep(.el-carousel__arrow) {
    background-color: transparent;
  }

  :deep(.el-carousel__arrow .el-icon) {
    font-size: 40px;
  }

  .index-banner-pic {
    width: 100%;
  }

  :deep(.el-image__inner) {
    width: 100%;
    /*height: auto;*/
  }

  .index-new {
    display: flex;
    margin-top: 20px;
  }

  .index-new-one {
    flex: 1;
    cursor: pointer;
  }

  .news-title {
    margin-top: 10px;
    font-size: 18px;
    font-weight: bold;
    color: var(--color-text);
    margin-bottom: 20px;
  }

  .news-box {
    display: flex;
  }

  .news-pic {
    width: 172px;
    height: 98px;
    margin-right: 13px;
  }

  .news-content {
    width: 375px;
    color: #808080;
    font-size: var(--font-size);
    display: flex;
    flex-direction: column;
  }

  .news-content-one {
    flex: 1;
    line-height: 28px;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    margin-top: -7px;
  }

  .news-content-two {
    text-align: right;
    color: #DF0615;
  }

  .index-new-two {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
  }

  .news-tabs {
    width: 505px;
  }

  :deep(.el-tabs__item) {
    font-size: 16px;
    color: #666;
    height: 30px;
    line-height: 30px;
    padding: 0 10px;
  }

  :deep(.el-tabs__item.is-active), :deep(.el-tabs__item.is-focus), :deep(.el-tabs__item:hover) {
    color: #DF0615;
  }

  :deep(.el-tabs__active-bar) {
    background-color: #DF0615;
  }
  .news-tabs-box {
    display: flex;
    flex-direction: column;
    font-size: var(--font-size);
  }
  .news-tabs-item-box {
    display: flex;
    align-items: center;
    color: #808080;
    margin-bottom: 15px;
  }
  .news-tabs-date {
    margin-right: 12px;
  }
  .news-tabs-more {
    text-align: right;
    color: #DF0615;
  }
  .index-case {
    margin-top: 60px;
  }
  .index-case-title {
    text-align: center;
  }
  .index-case-label {
    font-size: 30px;
    font-weight: bold;
    color: var(--color-text);
  }
  .index-case-line {
    margin: auto;
    margin-top: 8px;
    height: 2px;
    background-color: #C81125;
    width: 24px;
  }
  .index-case-b-label {
    font-size: 40px;
    font-weight: bold;
    color: #e6e6e6;
    margin-top: 20px;
  }
  .index-case-box {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .index-case-item {
    margin-top: 30px;
    width: 380px;
    text-align: center;
    background-color: #f7f7f7;
    padding-bottom: 30px;
    cursor: pointer;
  }
  .index-case-item-pic {
    width: 100%;
    height: 200px;
  }
  .index-case-item-title {
    font-size: 18px;
    margin-top: 20px;
    color: var(--color-text);
  }
  .index-case-item-line {
    margin: auto;
    margin-top: 10px;
    height: 2px;
    background-color: #C81125;
    width: 24px;
  }
  .index-case-item-desc {
    color: #b3b3b3;
    font-size: var(--font-size);
    margin: 25px 0 0;
  }
  .index-sat {
    margin-top: 60px;
    background-image: url('~assets/images/bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    height: 480px;
    display: flex;
    flex-direction: column;
  }
  .index-sat-tip {
    flex: 1;
    margin-top: 60px;
    display: flex;
    justify-content: center;
    color: #C81125;
    font-weight: bold;
  }
  .index-sat-item {
    margin-right: 100px;
  }
  .index-sat-item:last-child {
    margin-right: 0;
  }
  .index-sat-item-title {
    font-size: 50px;
  }
  .index-sat-item-desc {
    margin-top: 10px;
    font-size: 18px;
  }
  .index-sat-btn {
    text-align: center;
    margin-bottom: 40px;
  }
  .index-sat-btn-more {
    width: 220px;
    height: 50px;
    font-size: 18px;
    font-weight: bold;
    color: #fff;
    border-radius: 8px;
    background-color: #C81125;
  }
  .index-pro {
    margin-top: 60px;
  }
  .index-pro-box {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .index-pro-item {
    margin-top: 30px;
    width: 380px;
    height: 324px;
    padding: 15px;
    background-color: #fafafa;
    border: 1px solid #E5E5E5;
    cursor: pointer;
  }
  .index-pro-item:hover {
    border: none;
    box-shadow: 0 0 6px 0 rgba(179, 179, 179, 0.5);
  }
  .index-pro-item-pic {
    width: 350px;
    height: 208px;
  }
  .index-pro-item-title {
    font-size: 18px;
    font-weight: bold;
    margin-top: 20px;
  }
  .index-pro-item-desc {
    margin-top: 8px;
    font-size: var(--font-size);
    color: #b3b3b3;
    line-height: 18px;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .index-pro-btn {
    margin-top: 40px;
    text-align: center;
  }
  .index-pro-btn-more {
    width: 220px;
    height: 50px;
    font-size: 18px;
    font-weight: bold;
    color: #fff;
    border-radius: 8px;
    background-color: #C81125;
  }
  .index-link {
    height: 383px;
    background-color: #FAF7F8;
    margin-top: 40px;
    padding-top: 40px;
  }
  .index-link-banner {
    margin-top: 30px;
  }
  .index-link-banner :deep(.el-carousel__arrow .el-icon) {
    font-size: 30px;
    color: #ccc;
  }
  .index-link-banner :deep(.el-carousel__arrow--left) {
    left: 0;
  }
  .index-link-banner :deep(.el-carousel__arrow--right) {
    right: 0;
  }
  .index-link-box {
    padding: 0 42px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .index-link-item {
    width: 168px;
    height: 80px;
    cursor: pointer;
    text-align: center;
  }
  .index-link-item .el-image {
    height: 100%;
  }
</style>
